<template>
    <div style="overflow: hidden;" class="px-2">
        <h5 class="body-1 orange--text font-weight-bold">Filter Flights ({{flightsCount}} Flights)</h5>
        <v-divider class="my-3"></v-divider>
        <div @click="showPriceFilter = !showPriceFilter" class="d-flex justify-space-between">
          <h5 class="body-1 font-weight-bold blueDark--text mb-2">Prices</h5>
          <v-icon right v-text="showPriceFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
        <v-expand-transition>
          <div v-show="showPriceFilter">
            <v-row justify="space-between" v-if="min < max">
              <v-subheader class="subheaderGrey--text caption">{{prices[0] | changeCurrency(prices[0])}}</v-subheader>
              <v-subheader class="subheaderGrey--text caption">{{prices[1] | changeCurrency(prices[1])}}</v-subheader>
            </v-row>
            <v-row justify="space-between" v-else>
              <v-subheader color="subheaderGrey text-center caption">{{min | changeCurrency(min)}}</v-subheader>
            </v-row>
            <v-range-slider
            v-model="prices"
            :min="min"
            :max="max"
            color="orange"
            hide-details
            class="align-center"
            @change="filterate()"
            v-if="min < max"
            >
            </v-range-slider>
          </div>
        </v-expand-transition>

        <v-divider class="my-3"></v-divider>

        <div v-if="$store.state.flightType !== 'multi'" class="my-5">
          <div @click="showTimesFilter = !showTimesFilter" class="d-flex justify-space-between">
            <h5 class="body-1 font-weight-bold blueDark--text mb-2">Flight Times</h5>
            <v-icon right v-text="showTimesFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
          </div>
          <v-expand-transition>
        <div v-show="showTimesFilter">
        <h5 class="body-1 font-weight-bold orange--text">Going To {{$store.state.airports.arrivalAirports[0].Name}}</h5>

        <v-card class="pa-1 my-5">
          <h4 class="subheaderGrey--text font-weight-light">Depart times</h4>
          <v-row justify="space-between">
            <v-subheader class="orange--text caption">{{minDepartTime1}}</v-subheader>
            <v-subheader class="orange--text caption">{{maxDepartTime1}}</v-subheader>
          </v-row>
          <v-range-slider
          v-model="departTimes1"
          :min="0"
          :max="1425"
          step="15"
          color="orange"
          hide-details
          class="align-center"
          @input="timesChanged('depart1', departTimes1)"
          >
          </v-range-slider>
          <h4 class="subheaderGrey--text font-weight-light mt-2">Arrival times</h4>
          <v-row justify="space-between">
            <v-subheader class="orange--text caption">{{minArrivalTime1}}</v-subheader>
            <v-subheader class="orange--text caption">{{maxArrivalTime1}}</v-subheader>
          </v-row>
          <v-range-slider
          v-model="arrivalTimes1"
          :min="0"
          :max="1425"
          step="15"
          color="orange"
          hide-details
          class="align-center"
          @input="timesChanged('arrival1', arrivalTimes1)"
          >
          </v-range-slider>
        </v-card>

        <div v-if="$store.state.flightType === 'round'">
        <h5 class="body-1 font-weight-bold orange--text">Return To {{$store.state.airports.departureAirports[0].Name}}</h5>
        <v-card class="pa-1 my-5">
        <h4 class="subheaderGrey--text font-weight-light">Depart times</h4>
        <v-row justify="space-between">
        <v-subheader class="orange--text caption">{{minDepartTime2}}</v-subheader>
        <v-subheader class="orange--text caption">{{maxDepartTime2}}</v-subheader>
        </v-row>
        <v-range-slider
        v-model="departTimes2"
        :min="0"
        :max="1425"
        step="15"
        color="orange"
        hide-details
        class="align-center"
        @input="timesChanged('depart2', departTimes2)"
        >
        </v-range-slider>

        <h4 class="subheaderGrey--text font-weight-light mt-2">Arrival times</h4>
        <v-row justify="space-between">
        <v-subheader class="orange--text caption">{{minArrivalTime2}}</v-subheader>
        <v-subheader class="orange--text caption">{{maxArrivalTime2}}</v-subheader>
        </v-row>
        <v-range-slider
        v-model="arrivalTimes2"
        :min="0"
        :max="1425"
        step="15"
        color="orange"
        hide-details
        class="align-center"
        @input="timesChanged('arrival2', arrivalTimes2)"
        >
        </v-range-slider>
        </v-card>
        </div>
        </div>
      </v-expand-transition>

        </div>
        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="secondary" class="body-1">Stop Duration</h5>
        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="secondary" class="caption mt-2 mb-5"><strong>Stop Duration Range: <span class="secondary--text">{{convertDecimalToText(stopsDurationRange[0])}}</span> - <span class="secondary--text">{{convertDecimalToText(stopsDurationRange[1])}}</span></strong></h5>
        <v-range-slider
        v-model="stopsDurationRange"
        :min="minStopsDuration.value"
        :max="maxStopsDuration.value"
        step="0.25"
        color="secondary"
        hide-details
        class="align-center"
        @change="filterate()"
        v-if="maxStopsDuration.value - minStopsDuration.value > 0" >
        </v-range-slider>
        <v-divider></v-divider>
        <v-checkbox v-if="alternateFlightsExist" v-model="alternateFares" color="orange" @change="filterate()">
          <template v-slot:label>
            <span class="body-1">
              Alternate Dates' Fares Only
              <br>
              ({{firstAlternateFlight.pricingInfo.TotalFare.toFixed(2) | changeCurrency(firstAlternateFlight.pricingInfo.TotalFare.toFixed(2))}}
              on {{firstAlternateFlight.departureDate | formatDate(firstAlternateFlight.departureDate)}})
            </span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="mailonlyFaresExist && !$cookies.isKey('userToken')" v-model="mailOnlyFares" color="orange" @change="filterate()">
          <template v-slot:label>
            <span class="body-1">Email Only Fare</span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="alternateFlightsExist || mailonlyFaresExist" v-model="exactFares" color="orange" @change="filterate()">
          <template v-slot:label>
            <span class="body-1">Requested Date Flights</span>
          </template>
        </v-checkbox>
        <v-divider></v-divider>
        <div @click="showStopsFilter = !showStopsFilter" class="d-flex justify-space-between">
          <h5 class="body-1 font-weight-bold blueDark--text mb-2">Stops</h5>
          <v-icon right v-text="showStopsFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
          <v-expand-transition>
            <div v-show="showStopsFilter">
              <v-checkbox v-for="(item, i) in stops" :key="i" v-model="selectedStops" :value="item" color="orange" @change="filterate()">
                  <template v-slot:label>
                    <p class="body-1 font-weight-medium mb-0">{{item === 0 ? 'Non Stop' : (item === 1 ? 'One Stop' : 'Multi Stop')}}</p>
                  </template>
              </v-checkbox>
            </div>
          </v-expand-transition>

        <v-divider></v-divider>

        <div @click="showAirliesFilter = !showAirliesFilter" class="d-flex justify-space-between">
          <h5 class="body-1 font-weight-bold blueDark--text mb-2">Airlines</h5>
          <v-icon right v-text="showAirliesFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>

        <v-expand-transition>
          <div v-show="showAirliesFilter">
              <v-checkbox v-model="airs" v-for="(item, i) in airlines" :key="i" :disabled="airlines.length < 2" :value="item.airlineCode" color="orange" @change="filterate()">
                  <template v-slot:label>
                    <v-tooltip color="orange" bottom>
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="body-1 font-weight-medium mb-0">{{item.airlineCode}}<small>/ {{item.price | changeCurrency(item.price)}}</small></p>
                      </template>
                      <span>{{item.airlineName}}</span>
                    </v-tooltip>
                  </template>
              </v-checkbox>
          </div>
        </v-expand-transition>
        <v-divider></v-divider>

        <div v-if="stopAirports.length > 0">
          <div @click="showStopsAirportsFilter = !showStopsAirportsFilter" class="d-flex justify-space-between">
            <h5 class="body-1 font-weight-bold blueDark--text mb-2">Stops Airports</h5>
            <v-icon right v-text="showStopsAirportsFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
          </div>
          <v-expand-transition>
            <div v-show="showStopsAirportsFilter">
              <v-checkbox v-for="(item, i) in stopAirports" :key="i" v-model="selectedStopAirports" :value="item" color="orange" @change="filterate()">
                <template v-slot:label>
                  <v-tooltip color="orange" bottom>
                    <template v-slot:activator="{ on }">
                      <p v-on="on" class="body-1 font-weight-medium mb-0 text-truncate" style="max-width: 70%;">{{item}}</p>
                    </template>
                    <span>{{item}}</span>
                  </v-tooltip>
                </template>
              </v-checkbox>
            </div>
          </v-expand-transition>
        </div>

        <v-divider></v-divider>

        <div @click="showAircraftModelFilter = !showAircraftModelFilter" class="d-flex justify-space-between">
          <h5 class="body-1 font-weight-bold blueDark--text mb-2">Aircrafts</h5>
          <v-icon right v-text="showAircraftModelFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
        </div>
        <v-expand-transition>
          <div v-show="showAircraftModelFilter">
            <v-checkbox v-for="(item, i) in aircrafts" :key="i" v-model="selectedAircrafts" :disabled="aircrafts.length < 2" :value="item" color="orange" @change="filterate()">
              <template v-slot:label>
                <v-tooltip color="orange" bottom>
                  <template v-slot:activator="{ on }">
                    <p v-on="on" class="body-1 font-weight-medium mb-0">{{item}}</p>
                  </template>
                  <span>{{item}}</span>
                </v-tooltip>
              </template>
            </v-checkbox>
          </div>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
  props: ['alternateFlightsExist', 'mailonlyFaresExist'],
  // components: { VueRangeSlider },
  data () {
    return {
      showPriceFilter: true,
      showTimesFilter: true,
      showStopsFilter: true,
      showAirliesFilter: true,
      showStopsAirportsFilter: true,
      showAircraftModelFilter: true,
      prices: [],
      showArrival1: false,
      showArrival2: false,
      departTimes1: [0, 1425],
      arrivalTimes1: [0, 1425],
      departTimes2: [0, 1425],
      arrivalTimes2: [0, 1425],
      min: 0,
      max: 0,
      minDepartTime1: '12:00 AM',
      maxDepartTime1: '11:45 PM',
      minArrivalTime1: '12:00 AM',
      maxArrivalTime1: '11:45 PM',
      minDepartTime2: '12:00 AM',
      maxDepartTime2: '11:45 PM',
      minArrivalTime2: '12:00 AM',
      maxArrivalTime2: '11:45 PM',
      minStopsDuration: '',
      maxStopsDuration: '',
      stopsDurationRange: [],
      airs: [],
      airlines: [],
      alternateFares: false,
      mailOnlyFares: true,
      exactFares: true,
      flights: [],
      aircrafts: [],
      selectedAircrafts: [],
      stops: [],
      selectedStops: [],
      stopAirports: [],
      selectedStopAirports: [],
      flightsCount: 0,
      enableCross: false,
      tooltipMerge: false,
      formatter: value => `$${value}`
    }
  },
  computed: {
    flightResults () {
      return this.$store.state.flightResults
    },
    firstAlternateFlight () {
      return this.flights.find(flight => flight.withAlternateDate)
    }
  },
  watch: {
    flightResults (newVal, oldVal) {
      this.min = Math.floor(newVal.minPrice)
      this.max = Math.floor(newVal.maxPrice)
      this.prices = [this.min, this.max]
      this.airlines = newVal.airlines
      this.flights = newVal.flights
      this.airs = []
    }
  },
  methods: {
    convertDecimalToText (num) {
      let hours = 0
      if (Number(String(num).split('.')[0]) > 0) hours = Math.floor(num)
      let minutes = 0
      if ((Number(String(num).split('.')[1]) > 0)) minutes = (Number(String(num).split('.')[1]) * 60) / 100
      return hours + ' h ' + Math.floor(minutes) + ' m '
    },
    timesChanged (type, times) {
      switch (type) {
        case 'depart1':
          this.minDepartTime1 = this.formatTimes(times[0])
          this.maxDepartTime1 = this.formatTimes(times[1])
          break
        case 'arrival1':
          this.minArrivalTime1 = this.formatTimes(times[0])
          this.maxArrivalTime1 = this.formatTimes(times[1])
          break
        case 'depart2':
          this.minDepartTime2 = this.formatTimes(times[0])
          this.maxDepartTime2 = this.formatTimes(times[1])
          break
        case 'arrival2':
          this.minArrivalTime2 = this.formatTimes(times[0])
          this.maxArrivalTime2 = this.formatTimes(times[1])
          break
      }
      this.filterate()
    },
    formatTimes (time) {
      let hours = Math.floor(Number(time) / 60)
      let minutes = time - (hours * 60)
      if (hours.length === 1) hours = '0' + hours
      if (minutes.length === 1) minutes = '0' + minutes
      if (minutes === 0) minutes = '00'
      if (hours >= 12) {
        if (hours === 12) {
          minutes = minutes + ' PM'
        } else {
          hours = hours - 12
          minutes = minutes + ' PM'
        }
      } else {
        minutes = minutes + ' AM'
      }
      if (hours === 0) {
        hours = 12
      }
      return hours + ':' + minutes
    },
    convertTime (time) {
      const suffix = time.split(' ')[1]
      let hour = time.split(' ')[0].split(':')[0]
      const minutes = time.split(' ')[0].split(':')[1]
      if (suffix === 'PM') {
        if (hour !== '12') {
          hour = hour * 1 + 12
        }
      } else if (suffix === 'AM' && hour === '12') {
        hour = '00'
      }
      return hour + ':' + minutes + ':00'
    },
    filterate () {
      if (this.mailOnlyFares === null) this.mailOnlyFares = false
      const flights = []
      this.flights.forEach(flight => {
        const normalFare = this.exactFares ? !flight.withAlternateDate && !flight.mailOnlyFare : flight.withAlternateDate && flight.mailOnlyFare
        if ((this.alternateFares && flight.withAlternateDate) || normalFare || (this.mailOnlyFares && flight.mailOnlyFare)) {
          if (this.alternateFares && this.mailOnlyFares) flights.push(flight)
          else if (!(flight.withAlternateDate && flight.mailOnlyFare)) flights.push(flight)
        }
      })
      const stopsMatched = this.matchStops(flights)
      const airlinesMatched = this.matchAirlines(stopsMatched)
      const aircraftMatched = this.matchAircrafts(airlinesMatched)
      const pricesMatched = this.matchPrices(aircraftMatched)
      const timesMatched = this.matchTimes(pricesMatched)
      const layoverLocationMatched = this.matchLayover(timesMatched)
      const stopsDurationMatched = this.matchStopsDurations(layoverLocationMatched)
      const uniqueFlights = this.removeDuplicates(stopsDurationMatched)
      const sortedFlights = this.sortFlightsOnPrice(uniqueFlights)
      this.flightsCount = sortedFlights.length
      this.$emit('changed', sortedFlights)
    },
    matchStops (flights) {
      const newFlights = []
      let validFlight
      this.selectedStops.forEach(stop => {
        switch (stop) {
          case 0:
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops === 0) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
          case 1:
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops === 1) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
          default :
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops > 1) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
        }
      })
      return newFlights
    },
    matchPrices (flights) {
      const newFlights = []
      flights.forEach(flight => {
        if (this.prices[1] >= Math.floor(flight.pricingInfo.TotalFare) && Math.floor(flight.pricingInfo.TotalFare) >= this.prices[0]) newFlights.push(flight)
      })
      return newFlights
    },
    matchAirlines (flights) {
      let newFlights = []
      if (this.airs.length > 0) {
        flights.forEach(flight => {
          flight.flightSegments.forEach(trip => {
            trip.Segments.forEach(segment => {
              this.airs.forEach(airline => {
                if (airline === segment.MarketingAirlineCode) newFlights.push(flight)
              })
            })
          })
        })
      } else newFlights = flights
      return newFlights
    },
    matchAircrafts (flights) {
      let newFlights = []
      if (this.selectedAircrafts.length > 0) {
        flights.forEach(flight => {
          flight.flightSegments.forEach(trip => {
            trip.Segments.forEach(segment => {
              this.selectedAircrafts.forEach(aircraft => {
                if (aircraft === segment.aircraft) newFlights.push(flight)
              })
            })
          })
        })
      } else newFlights = flights
      return newFlights
    },
    matchTimes (flights) {
      const timeFiltered = []
      flights.forEach(flight => {
        if (this.$store.state.flightType === 'one') {
          const segments = flight.flightSegments[0].Segments
          if (this.convertTime(this.minDepartTime1) < segments[0].DepartureTime + ':00' && segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < segments[segments.length - 1].ArrivalTime + ':00' && segments[segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
            timeFiltered.push(flight)
          }
        } else if (this.$store.state.flightType === 'round') {
          const flightSegments = flight.flightSegments
          if (this.convertTime(this.minDepartTime1) < flightSegments[0].Segments[0].DepartureTime + ':00' && flightSegments[0].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' && flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
            if (this.convertTime(this.minDepartTime2) < flightSegments[1].Segments[0].DepartureTime + ':00' && flightSegments[1].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime2) && this.convertTime(this.minArrivalTime2) < flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' && flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime2)) {
              timeFiltered.push(flight)
            }
          }
        } else {
          timeFiltered.push(flight)
        }
      })
      return timeFiltered
    },
    matchLayover (flights) {
      if (this.stopAirports.length > 0) {
        if (this.selectedStopAirports.length > 0) {
          const newFlights = []
          flights.forEach(flight => {
            flight.flightSegments.forEach(trip => {
              trip.Segments.forEach((segment, i) => {
                this.selectedStopAirports.forEach(airport => {
                  if (i < trip.Segments.length) {
                    if (airport === segment.DestinationLocation) newFlights.push(flight)
                  }
                })
              })
            })
          })
          return newFlights
        } else return flights
      } else return flights
    },
    matchStopsDurations (flights) {
      if (this.stopsDurationRange[1] - this.stopsDurationRange[0] > 0) {
        const newFlights = []
        flights.forEach((flight, j) => {
          const validDurations = []
          flight.flightSegments.forEach((trip, i) => {
            if (trip.stopsDuration.length) {
              trip.stopsDuration.forEach((duration, l) => {
                if (Number(this.stopsDurationRange[0]) <= Number(duration.value) && Number(duration.value) <= Number(this.stopsDurationRange[1])) validDurations.push('inrange')
                else validDurations.push('outrange')
              })
            } else {
              validDurations.push('inrange')
            }
          })
          const valid = validDurations.includes('inrange')
          if (valid) newFlights.push(flight)
        })
        return newFlights
      } else return flights
    },
    removeDuplicates (flights) {
      const uniqueFlights = flights.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      return uniqueFlights
    },
    sortFlightsOnPrice (flights) {
      const newFlights = flights.sort((a, b) => parseFloat(a.pricingInfo.TotalFare) - parseFloat(b.pricingInfo.TotalFare))
      return newFlights
    },
    getUniqueList (type, data) {
      switch (type) {
        case 'airline':
          this.airlines = data.reduce((acc, current) => {
            const x = acc.find(item => item.airlineCode === current.airlineCode)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.airlines.map(item => this.airs.push(item.airlineCode))
          break
        case 'aircraft':
          this.aircrafts = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.aircrafts.map(item => this.selectedAircrafts.push(item))
          break
        case 'stops':
          this.stops = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          this.stops.map(item => this.selectedStops.push(item))
          break
        case 'stopAirports':
          this.stopAirports = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.stopAirports.map(item => this.selectedStopAirports.push(item))
          break
      }
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.flightResults.minPrice)
    this.max = Math.floor(this.$store.state.flightResults.maxPrice)
    this.minStopsDuration = this.$store.state.flightResults.minStopsDuration
    this.maxStopsDuration = this.$store.state.flightResults.maxStopsDuration
    this.stopsDurationRange = [this.minStopsDuration.value, this.maxStopsDuration.value]
    this.prices = [this.min, this.max]
    this.getUniqueList('airline', this.$store.state.flightResults.airlines)
    this.getUniqueList('aircraft', this.$store.state.flightResults.aircraftModel)
    this.getUniqueList('stops', this.$store.state.flightResults.stops)
    this.getUniqueList('stopAirports', this.$store.state.flightResults.stopOverAirports)
    this.flights = this.$store.state.flightResults.flights
    this.flightsCount = this.$store.state.flightResults.flights.length
  }
}
</script>

<style>
.v-slider--horizontal .v-slider__track-container {
height: 4px !important;
}
/* .vue-range-slider.slider-component .slider .slider-process {
background-color: #FF8F95;
}
.vue-range-slider.slider-component .slider .slider-dot {
box-shadow: 0px 3px 6px #00000029;
} */
.v-slider__thumb::after {
  content: "";
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 2px #cccccc4a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>
